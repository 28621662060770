import React, { ReactNode, useState } from 'react';
import { useForm } from 'react-hook-form';
import Send from 'src/icons/send';
import { PortalWithState } from 'react-portal';
import { RemoveScroll } from 'react-remove-scroll';
import X from 'src/icons/x';
import Link from 'next/link';
import { buttonCss } from '../button/buttons.css';
import { inputCss } from './email_signup.css';
import { ErrorToast, SuccessToast } from '../toasts';

export type EmailSignupFormInputs = {
  email: string;
};

function Label({ text, htmlFor }: { text: string; htmlFor: string }) {
  return (
    <label htmlFor={htmlFor} className="block dsw-body">
      {text}
    </label>
  );
}

function InputError({ children }: { children: ReactNode }) {
  return (
    <div role="alert" className="text-red11">
      {children}
    </div>
  );
}

function EmailSignupForm({
  closePortal,
  onSuccess,
  onError,
}: {
  closePortal: () => void;
  onSuccess: () => void;
  onError: (errorMsg: string) => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailSignupFormInputs>();

  const onSubmit = async (data: EmailSignupFormInputs) => {
    const dataToSend = {
      ...data,
    };

    setIsSubmitting(true);

    const res = await fetch('/api/general_mail_signup', {
      method: 'POST',
      body: JSON.stringify({ data: dataToSend }),
      headers: { 'Content-Type': 'application/json' },
    });

    setIsSubmitting(false);
    closePortal();

    if (res.ok) {
      onSuccess();
    } else {
      onError(res.statusText);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          role="dialog"
          className="z-10 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-3xl bluegum text-grassTree overflow-y-auto rounded-md bg-paperDaisy shadow-lg"
        >
          <RemoveScroll enabled removeScrollBar={false}>
            <div className="mx-4 mt-4 mb-6 grid grid-flow-row gap-8">
              <div>
                <div className="text-right">
                  <button title="Cancel signup" type="button" onClick={() => closePortal()}>
                    <X />
                  </button>
                </div>
                <div className="dsw-h4">Mailing list signup</div>
              </div>

              <div>
                You can unsubscribe anytime. For more details, review our{' '}
                <Link href="/privacy" passHref>
                  <a className="underline" href="dummy" target="_blank">
                    Privacy Policy
                  </a>
                </Link>
                .
              </div>
              <div>
                <Label htmlFor="email" text="Your email *" />
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    aria-invalid={errors.email ? 'true' : 'false'}
                    {...register('email', { required: true })}
                    className={inputCss({ size: 'medium' })}
                    spellCheck="false"
                    data-ms-editor="true"
                  />
                  {errors.email?.type === 'required' && <InputError>Email is required</InputError>}
                </div>
              </div>
            </div>

            <div className="text-right bg-gray3 px-4 py-5">
              <button type="submit" className={buttonCss({})} disabled={isSubmitting}>
                <Send className={isSubmitting ? 'inline animate-spin mr-2' : 'inline  mr-2'} /> Send
              </button>
            </div>
          </RemoveScroll>
        </div>
      </form>
    </>
  );
}

export default function EmailSignupFormDialog() {
  const [successToastOpen, setSuccessToastOpen] = React.useState(false);
  const [errorToastOpen, setErrorToastOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {({ openPortal, closePortal, portal }) => (
        <>
          <button
            type="button"
            className={buttonCss({ intent: 'primary', style: 'subdued' })}
            onClick={openPortal}
          >
            Subscribe
          </button>

          <SuccessToast open={successToastOpen} onOpenChange={setSuccessToastOpen}>
            Thanks for signing up! Please check your inbox for a confirmation email
          </SuccessToast>
          <ErrorToast open={errorToastOpen} onOpenChange={setErrorToastOpen}>
            There was an error signing you up - {errorMessage}.
          </ErrorToast>

          {portal(
            <div>
              {/* Overlay */}
              <div
                role="presentation"
                className="z-10  fixed left-0 top-0 w-screen h-screen bg-blackA11"
                onClick={() => closePortal()}
              />
              <EmailSignupForm
                closePortal={closePortal}
                onSuccess={() => setSuccessToastOpen(true)}
                onError={(errorMsg) => {
                  setErrorMessage(errorMsg);
                  setErrorToastOpen(true);
                }}
              />
            </div>,
          )}
        </>
      )}
    </PortalWithState>
  );
}
