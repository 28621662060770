import React from 'react';

export default function CardCornerHide() {
  return (
    <>
      <div className="border-gray8 border-solid border-r top-2 bottom-2 left-[-1px] w-0 absolute" />
      <div className="border-gray8 border-solid border-r top-2 bottom-2 right-[-1px] w-0 absolute" />

      <div className="border-gray8 border-solid border-b top-[-1px] h-0 left-4 right-4 absolute" />
      <div className="border-gray8 border-solid border-b bottom-[-1px] h-0 left-4 right-4 absolute" />
    </>
  );
}
