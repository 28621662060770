import {
  getSolidColorImageDataUrl,
  sixByFourAspectRatio,
  sixByFourImageLoader,
} from '@/lib/images';
import React, { useMemo } from 'react';
import Image from 'next/image';
import { get2ColumnGridImageSizes } from '@/styles/breakpoints';

type LargeCardImageProps = {
  imageRef: string;
  width: number;
  dominantColor: string;
  priority?: boolean;
  alt: string;
};

export function LargeCardImage({
  imageRef,
  width,
  dominantColor,
  priority,
  alt,
}: LargeCardImageProps) {
  const solidColorImage = useMemo(() => getSolidColorImageDataUrl(dominantColor), [dominantColor]);

  return (
    <div className="embla__slide">
      <Image
        layout="responsive"
        loader={sixByFourImageLoader}
        src={imageRef}
        width={width}
        height={width / sixByFourAspectRatio}
        style={{ backgroundImage: solidColorImage }}
        sizes={get2ColumnGridImageSizes()}
        priority={priority}
        alt={alt}
      />
    </div>
  );
}
