// Note these MUST be kept in sync with the values in tailwind.config.js
// eslint-disable-next-line import/prefer-default-export
export const screenBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export const maxWidthSizes = {
  xs: { width: 20, units: 'rem' },
  sm: { width: 24, units: 'rem' },
  md: { width: 28, units: 'rem' },
  lg: { width: 32, units: 'rem' },
  xl: { width: 36, units: 'rem' },
  '2xl': { width: 42, units: 'rem' },
  '3xl': { width: 48, units: 'rem' },
  '4xl': { width: 56, units: 'rem' },
  '5xl': { width: 64, units: 'rem' },
  '6xl': { width: 72, units: 'rem' },
  '7xl': { width: 90, units: 'rem' },
};

export function get4ColumnGridImageSizes() {
  return `(min-width: ${screenBreakpoints['2xl']}px) ${Math.round(
    screenBreakpoints['2xl'] / 4,
  )}px, (min-width: ${screenBreakpoints.xl}px) 25vw, (min-width: ${
    screenBreakpoints.lg
  }px) 33vw, (min-width: ${screenBreakpoints.sm}px) 50vw, 600px`;
}

export function get2ColumnGridImageSizes() {
  return `(min-width: ${screenBreakpoints['2xl']}px) ${Math.round(
    screenBreakpoints['2xl'] / 2,
  )}px, (min-width: ${screenBreakpoints.lg}px) 50vw, 600px`;
}
