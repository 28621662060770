import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

type MetaTagsProps = {
  title?: string | null;
  description?: string | null;
  imageUrl?: string | null;
};

export function MetaTags({ title, description, imageUrl }: MetaTagsProps) {
  const router = useRouter();

  const domain = process.env.NEXT_PUBLIC_URL;
  const url = `${domain}${router.asPath}`;

  const titleToUse = title || 'Down South Weddings';
  const descriptionToUse =
    description ||
    'South West vendors, venues and accommodation for weddings in Margaret River, Dunsborough, Yallingup, Albany and down south WA.';
  const imageUrlToUse =
    imageUrl || 'https://images.downsouthweddings.com.au/production/cl2zt19pt00036zqj2par1gan.jpg';

  return (
    <Head>
      <link rel="canonical" href={url} />
      <meta charSet="UTF-8" />

      <title>{titleToUse}</title>
      <meta name="description" content={descriptionToUse} />

      {/* Facebook Meta Tags */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={titleToUse} />
      <meta property="og:description" content={descriptionToUse} />
      <meta property="og:image" content={imageUrlToUse} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={domain} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={titleToUse} />
      <meta name="twitter:description" content={descriptionToUse} />
      <meta name="twitter:image" content={imageUrlToUse} />
    </Head>
  );
}
