import React, { useState, ReactNode } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { buttonCss } from '@/components/button/buttons.css';
import {
  GetPostSummariesDocument,
  useGetPostSummariesQuery,
  useGetSupplierCategoriesQuery,
  useGetVenueCategoriesQuery,
} from '@/generated/graphql';
import Head from 'next/head';
import EmailSignupFormDialog from '@/components/email_signup/EmailSignupFormDialog';
import { MetaTags } from '@/components/MetaTags';
import Camera from 'src/icons/camera';
import { imageInfoOverlayCss, imageInfoOverlayTextCss } from '@/styles/home.css';
import X from 'src/icons/x';
import { getFitInWidthImageLoader } from '@/lib/images';
import { stdGrid2ColCss } from '@/styles/styles.css';
import PostCard from '@/components/cards/PostCard';
import { GetServerSideProps } from 'next';
import { createUrqlClient } from '@/lib/urql';
import loadCommonPageData from '@/lib/loadCommonPageData';

const imageLoader = getFitInWidthImageLoader('production');

type HopepageImageWithInfoProps = {
  imageRef: string;
  children?: ReactNode;
  width: string | number;
  height: string | number;
  sizes?: string;
  priority?: boolean;
};
function HomepageImageWithInfo({
  imageRef,
  children,
  width,
  height,
  sizes,
  priority,
}: HopepageImageWithInfoProps) {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="relative overflow-hidden">
      <Image
        src={imageRef}
        loader={imageLoader}
        layout="responsive"
        width={width}
        height={height}
        sizes={sizes}
        priority={priority}
      />

      {children && (
        <div className="absolute right-4 top-4">
          <button
            type="button"
            title="Show photo credits"
            className={buttonCss({ style: 'subdued', rounded: true })}
            onClick={() => setShowInfo(true)}
          >
            <Camera size={20} />
          </button>
          <div className={imageInfoOverlayCss({ visible: showInfo })}>
            <div className={imageInfoOverlayTextCss({ visible: showInfo })}>
              <div>
                <button type="button" onClick={() => setShowInfo(false)}>
                  <X />
                </button>
              </div>
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function HomePageImgLink({
  href,
  target,
  children,
}: {
  href: string;
  target?: React.HTMLAttributeAnchorTarget;
  children: ReactNode;
}) {
  return (
    <Link href={href} passHref>
      <a className="underline" href={href} target={target}>
        {children}
      </a>
    </Link>
  );
}

function IntroSection() {
  const mailerliteScript = `(function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
    .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
    n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
    (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
    ml('account', '14774');`;
  return (
    <div className="bg-wattle pt-0 md:pt-10 pb-8">
      <Head>
        {/* eslint-disable-next-line react/no-danger */}
        <script dangerouslySetInnerHTML={{ __html: mailerliteScript }} />
      </Head>
      <div className="max-w-4xl mx-auto">
        <div className="relative">
          <HomepageImageWithInfo
            imageRef="s3:image:cl2zhbzkq000171p1h0vqde2p.jpg"
            width={1920}
            height={1280}
            sizes="580px"
            priority
          >
            Picture of <HomePageImgLink href="/venue/stonebarn">Stonebarn</HomePageImgLink> by{' '}
            <HomePageImgLink href="/supplier/nectarine">Nectarine</HomePageImgLink>
          </HomepageImageWithInfo>

          <div className="bg-paperDaisy px-4 py-4 w-full md:max-w-md md:absolute md:left-4 md:-top-4">
            <div className="dsw-body">Get married ~ Down South</div>
            <h1 className="dsw-h4 my-4 text-boronia">Your WA South West wedding&nbsp;directory</h1>
            <div>
              Wedding vendors, venues and accommodation for Margaret River, Dunsborough, Yallingup,
              Pemberton, Albany and beyond.
            </div>
            <div className="flex gap-4 mt-4">
              <Link href="/venues/all" passHref>
                <a href="dummy" className={buttonCss()}>
                  Venues
                </a>
              </Link>
              <Link href="/suppliers/all" passHref>
                <a href="dummy" className={buttonCss()}>
                  Suppliers
                </a>
              </Link>
              <Link href="/accommodations/all" passHref>
                <a href="dummy" className={buttonCss()}>
                  Stay
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function FindVenuesSection() {
  const [getVenueCategoriesQueryResult] = useGetVenueCategoriesQuery();
  const venueCategoriesData = getVenueCategoriesQueryResult.data;

  const topCategories = venueCategoriesData?.venueCategories?.slice(0, 8) || [];

  return (
    <div>
      <div className="bg-wattle pt-10 pb-24 lg:pb-0">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div />
            <div className="px-4 sm:px-0 text-center sm:text-left">
              <Link href="/venues/all" passHref>
                <a href="dummy" className="hover:underline">
                  <h2>Find Venues</h2>
                </a>
              </Link>
            </div>
            <div className="pr-20 sm:pr-0">
              <HomepageImageWithInfo
                imageRef="s3:image:cl37fp0wd000p71o72y43dy96.jpg"
                width={1600}
                height={2400}
                sizes="630px"
              >
                Photo of <HomePageImgLink href="/venue/wills-domain">Wills Domain</HomePageImgLink>{' '}
                by photographer Westerly
              </HomepageImageWithInfo>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div />
              <div className="mt-4 sm:mt-0 pl-20 sm:pl-4 md:pl-0">
                {topCategories.map((category) => (
                  <Link key={category.slug} href={`/venues/category/${category.slug}`} passHref>
                    <a href="dummy" className="dsw-h4 block py-1 hover:underline">
                      {category.name} &rarr;
                    </a>
                  </Link>
                ))}

                <div className="mt-4">
                  <Link href="/venues/all" passHref>
                    <a href="dummy" className={buttonCss()}>
                      See all
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-paperDaisy mt-0 lg:-mt-16 h-20">
        <div className="max-w-4xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <div />
            <div className="relative ">
              <div className="absolute left-8 -bottom-14">
                <Image
                  src="s3:image:cl2zlbxym000571p1bssk3kgm.png"
                  loader={imageLoader}
                  width={130}
                  height={120}
                  layout="intrinsic"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function SuppliersSection() {
  const [getSupplierCategoriesQueryResult] = useGetSupplierCategoriesQuery();
  const supplierCategoriesData = getSupplierCategoriesQueryResult.data;

  const topCategories = supplierCategoriesData?.supplierCategories?.slice(0, 8) || [];

  return (
    <div className="bg-paperDaisy pt-10">
      <div className="max-w-4xl mx-auto relative">
        <div className="absolute -left-20 top-8 right-20 -bottom-10 bg-violet" />
        <div className="grid grid-cols-1 sm:grid-cols-2 relative">
          <div />
          <div className="px-4 sm:px-0 text-center sm:text-left">
            <Link href="/suppliers/all" passHref>
              <a href="dummy" className="hover:underline">
                <h2>Find Suppliers</h2>
              </a>
            </Link>
          </div>
          <div className="px-8 sm:px--4 lg:px-0">
            {topCategories.map((category) => (
              <Link key={category.slug} href={`/suppliers/category/${category.slug}`} passHref>
                <a href="dummy" className="dsw-h4 block py-1 hover:underline">
                  {category.name} &rarr;
                </a>
              </Link>
            ))}

            <div className="mt-4">
              <Link href="/suppliers/all" passHref>
                <a href="dummy" className={buttonCss()}>
                  See all
                </a>
              </Link>
            </div>
          </div>
          <div className="mt-8 max-w-sm ml-20 sm:mt-0 sm:max-w-none sm:ml-0">
            <div className="mr-16">
              <HomepageImageWithInfo
                imageRef="s3:image:cl2x288y0000f71o1bb2fbfyt.jpg"
                width={1024}
                height={1536}
                sizes="470px"
              >
                Flowers by{' '}
                <HomePageImgLink href="/supplier/scentiment-flowers">
                  Scentiment Flowers
                </HomePageImgLink>{' '}
                - photo by Sarah Tonkin Photography
              </HomepageImageWithInfo>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto relative">
        <div className="mt-8 -ml-12 lg:-ml-32">
          <Image
            src="s3:image:cl2zlos3s000771p13a3k1b3q.png"
            loader={imageLoader}
            width={474}
            height={382}
            layout="intrinsic"
          />
        </div>
      </div>
    </div>
  );
}

function EmailSignupSection() {
  return (
    <div className="bg-orchid">
      <div className="max-w-2xl mx-auto relative pt-8 pb-6 px-4">
        <div className="dsw-h4 text-paperDaisy text-center">
          The latest &amp; greatest. Every month.
        </div>
        <div className="mt-4 text-center">
          <EmailSignupFormDialog />
        </div>
        <div className="text-paperDaisy pt-4 dsw-sm text-center">
          You&apos;re signing up for our newsletter: a round-up of venues, suppliers, real weddings
          and inspiration. We&apos;ll never pass on your email to anyone - promise.
        </div>
      </div>
    </div>
  );
}

function InspirationSection() {
  const [queryResult] = useGetPostSummariesQuery({ variables: { take: 2 } });
  const { data } = queryResult;

  return (
    <div className="py-8">
      <div className="max-w-4xl mx-auto">
        <Link href="/inspiration" passHref>
          <a href="dummy" className="hover:underline">
            <h2>Wedding Inspiration</h2>
          </a>
        </Link>

        {data && (
          <div className={stdGrid2ColCss}>
            {data.posts?.map((post, idx) => (
              <React.Fragment key={post.id}>
                <PostCard post={post} hasImagePriority={idx < 4} />
              </React.Fragment>
            ))}
            {(data.posts?.length || 0) % 2 === 1 && <div className="bg-paperDaisy" />}
          </div>
        )}
      </div>
    </div>
  );
}

function HomePage() {
  return (
    <div>
      <MetaTags />
      <div className="flex-grow">
        <IntroSection />
        <InspirationSection />
        <FindVenuesSection />
        <SuppliersSection />
        <EmailSignupSection />
      </div>
    </div>
  );
}

export default HomePage;

export const getServerSideProps: GetServerSideProps = async (): Promise<any> => {
  const { client, ssrCache } = createUrqlClient();

  if (client) {
    await loadCommonPageData(client);
    await client.query(GetPostSummariesDocument, { take: 2 }).toPromise();
  }
  return {
    props: {
      urqlState: ssrCache.extractData(),
    },
  };
};
