import Link from 'next/link';
import { PostSummary } from './types';
import CardCornerHide from './CardCornerHide';

import { LargeCardImage } from './LargeCardImage';
import { vendorCardClass } from './swiper.css';

export default function PostCard({
  post,
  hasImagePriority,
}: {
  post: PostSummary;
  hasImagePriority: boolean;
}) {
  if (!post) {
    return null;
  }

  return (
    <Link href={`/blog/${post.slug}`} passHref>
      <a href="dummy">
        <div className={vendorCardClass}>
          <div className="relative w-full h-full border-0 border-sand9 p-3 bg-paperDaisy">
            <CardCornerHide />
            {post.mainImage && (
              <LargeCardImage
                imageRef={post.mainImage.ref!}
                width={post.mainImage.width!}
                dominantColor={post.mainImage.dominantColor!}
                alt={post.title!}
                priority={hasImagePriority}
              />
            )}
            <div className="p-6">
              <h5 className="dsw-h4 mt-2 text-boronia">{post.title}</h5>
              {post.subheading && <div className="mt-2">{post.subheading}</div>}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}
