/* eslint-disable no-bitwise */

export function getSolidColorImageDataUrl(hexColor: string) {
  const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  const triplet = (e1: number, e2: number, e3: number) =>
    keyStr.charAt(e1 >> 2) +
    keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
    keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
    keyStr.charAt(e3 & 63);

  let s = hexColor.substring(1, 7);
  if (s.length < 6) s = s[0] + s[0] + s[1] + s[1] + s[2] + s[2];
  const [r, g, b] = [
    parseInt(s[0] + s[1], 16),
    parseInt(s[2] + s[3], 16),
    parseInt(s[4] + s[5], 16),
  ];

  const rgbDataURL = `url(data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==)`;

  return rgbDataURL;
}

export const fourbyThreeAspectRatio = 4 / 3;

export const fourByThreeImageLoader = ({
  src,
  width: reqWidth,
}: {
  src: string;
  width: number;
}) => {
  const filename = src.split(':')[2];
  return `https://images.downsouthweddings.com.au/${reqWidth}x${Math.round(
    reqWidth / fourbyThreeAspectRatio,
  )}/production/${filename}`;
};

export const sixteenByNineAspectRatio = 16 / 9;

export const sixteenByNineImageLoader = ({
  src,
  width: reqWidth,
}: {
  src: string;
  width: number;
}) => {
  const filename = src.split(':')[2];
  return `https://images.downsouthweddings.com.au/${reqWidth}x${Math.round(
    reqWidth / sixteenByNineAspectRatio,
  )}/production/${filename}`;
};

export const sixByFourAspectRatio = 6 / 4;

export const sixByFourImageLoader = ({ src, width: reqWidth }: { src: string; width: number }) => {
  const filename = src.split(':')[2];
  return `https://images.downsouthweddings.com.au/${reqWidth}x${Math.round(
    reqWidth / sixByFourAspectRatio,
  )}/production/${filename}`;
};

export function getImageLoader(environment: string, aspectRatio: number) {
  const ImageLoader = ({ src, width }: { src: string; width: number }) => {
    const filename = src.split(':')[2];
    return `https://images.downsouthweddings.com.au/${width}x${Math.round(
      width / aspectRatio,
    )}/${environment}/${filename}`;
  };
  return ImageLoader;
}

export function getFitInWidthImageLoader(environment: string) {
  const ImageLoader = ({ src, width }: { src: string; width: number }) => {
    const filename = src.split(':')[2];
    // Note that we use 10,000 as the height as an arbitrarily high size. In reality
    // fit-in will preserve the aspect ration so we're just asking for an image with
    // the correct aspect ration and a width no bigger than the specified width. In most
    // cases the image will need to be scaled down but not that fit-it does not scale
    // up images - this can be done with the browser.
    return `https://images.downsouthweddings.com.au/fit-in/${width}x10000/${environment}/${filename}`;
  };
  return ImageLoader;
}

export function generateImageSizes(
  numberOfColumns: number,
  maxWidth: number,
  maxWidthUnits: string,
) {
  return `(min-width: ${maxWidth}${maxWidthUnits}) ${Math.round(
    maxWidth / numberOfColumns,
  )}${maxWidthUnits}, calc(100vw / ${numberOfColumns})`;
}

export function getSocialMediaImageUrl(ref: string) {
  const filename = ref.split(':')[2];
  return `https://images.downsouthweddings.com.au/1200x630/production/${filename}`;
}
